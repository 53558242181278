.searchbar-dropdown {
  .form-control {
    background: #ffffff;
    box-shadow: none;
    border-radius: 11px;
    border: none;
    border-bottom: 1px solid #e3e3e3;
  }
}

.searchbar-dropdown-container {
  background-color: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 11px;

  &.is-active {
    padding: 0.5rem;

    input {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid#e3e3e3;
      box-shadow: none;
    }
  }

  input {
    height: 40px;
  }

  ul {
    padding-left: 2.375rem !important;
  }
}

.has-search .form-control {
  padding-left: 2.375rem !important;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  background-color: white;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.layout1-nav-links {
  font-family: "Montserrat";
  font-weight: 400 !important;
  line-height: 119.4%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #33363f !important;
}

.layout1-dir-hor-text {
  color: #33363f;
}

.search-results {
  max-height: 220px;
  overflow-y: auto;
  a {
    text-decoration: none;
    color: inherit;
  }
}

@media (max-width: 575.98px) {
  .search-results {
    a {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.no-access {
  .no-access-icon {
    width: 40%;
    margin-bottom: 20px;
  }

  .no-access-label {
    color: #4252c9 !important;
    font-weight: bold;
    font-size: 80px;
  }

  .no-access-desc {
    font-size: 18px;
    color: #000000;
    text-align: center;
  }

  .no-access-btn {
    background: #4252c9;
    box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
    border-radius: 11px;
    height: 45px;
    padding: 10px 20px;
    font-weight: bold;

    &:hover {
      background-color: #4252c9;
    }
  }
}

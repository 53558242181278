.custom-dropdown {
  &--container {
    width: 100%;
    select {
      background-color: #f8f8ff;
      border: 1px solid #656eb2;
      box-shadow: 0px 17px 24px -14px rgb(121 137 255 / 55%);
      border-radius: 12px;
      height: 80px;
      background-size: 20px 15px;
    }

    .pointer {
      .select__control {
        cursor: pointer;
      }
    }
  }
}

.select-container {
  .util-percentage {
    width: 30% !important;
  }

  .util-label {
    margin-right: 0.5rem;
  }

  .util-datepicker {
    width: 90% !important;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.utilization-status {
  margin-bottom: 3rem;
  .util-card {
    border-radius: 12px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .util-card-icon {
    background: #e0e7ff;
    border-radius: 50%;
    padding: 8px;
    margin-left: 16px;
    color: #0000ff;
  }
}

.settings-icon {
  display: flex;
  background: #e0e7ff;
  border-radius: 50%;
  padding: 8px;
  color: #0000ff;
}

.settings-hover {
  cursor: pointer;
}

.util-search-bar {
  width: 100%;
}

.form-date-implemented {
  .react-datepicker-wrapper {
    width: 100% !important;
  }
}

.utilization-list {
  .rdt_TableHead {
    display: none !important;
  }

  .rdt_TableRow {
    margin-top: 1rem;
  }

  .rdt_TableBody {
    p {
      font-size: 14px;
    }
  }
  .rdt_Pagination {
    span,
    .sc-eeMvmM,
    .cCMCmf {
      display: none !important;
    }
  }
}

.event-title {
  width: max-content;
}

.util-attachment {
  justify-content: center;
  align-items: center;
}

.clear-filter {
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  cursor: pointer;
}

.card.no-border {
  border: none; /* Remove border */
  border-radius: 8px;
  overflow: hidden;
}

.card-img {
  object-fit: contain;
}

.card-title {
  font-weight: bold;
}

.card-text {
  margin-bottom: 0.5rem;
}

.actions {
  margin-top: 10px;
}

.actions button {
  margin-right: 10px;
}

.util-upload-desc {
  margin-bottom: 0px;
}

.util-file-types {
  font-size: 12px;
}

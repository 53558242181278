.rep-container {
  background: #ffffff;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  height: 100px;
  width: 400px;
  text-decoration: none;
  color: initial;

  &--label {
    line-height: 27px;
    letter-spacing: 0.02em;
    text-align: center;
  }

  a {
    text-decoration: none;
    color: var(--bs-body-color);
  }
}

.rep-container:hover {
  background: #656eb2;

  .rep-container--label {
    color: white;
  }
}

@media (max-width: "768.98px") {
  .representatives-container {
    padding-left: 2rem !important;
    padding-right: 2rem !important;

    .rep-container--label {
      font-size: 1rem;
    }
  }

  .rep-container {
    width: 250px;
  }
}

@media (min-width: 768px) {
  .rep-container {
    width: 300px;
  }
}

@media (max-width: 575.98px) {
  .rep-container {
    width: 240px;
  }

  .representatives-container {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .rep-container {
    width: 260px;

    h5 {
      font-size: 1rem;
    }
  }
}

.add-project-container.directory {
  .form-section {
    @media (max-width: 575.98px) {
      .col--img-container {
        margin-bottom: 2rem;
      }
    }

    @media (max-width: 768.98px) {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }
}

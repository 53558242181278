.comments-modal {
  .comments-text {
    margin-bottom: 0;
    margin-left: 0.5rem;
  }

  .modal-dialog {
    position: fixed !important;
    right: 0 !important;
    margin: 0 !important;
  }

  .modal-content {
    overflow: hidden;
    height: 100vh;

    .form-section {
      margin-top: 4rem;
    }

    .row-section {
      margin-top: 1.5rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;

      @media (max-width: 575.98px) {
        flex-direction: column;
      }
    }

    .form-col-right {
      flex: 0 0 auto;
      width: 100%;

      @media (min-width: 768px) {
        flex: 0 0 auto;
        width: 50%;
      }

      .form-control {
        background: #f5f5f7;
        border-radius: 16px;
        height: 60px;

        &.input-textarea {
          height: initial;
        }
      }

      .react-datepicker-wrapper {
        width: 100%;
      }
    }

    .modal-body {
      overflow-y: auto;
    }
  }

  .modal-footer {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 50px;

    .send-btn-section {
      display: grid;
      justify-content: center;
    }
  }

  .header-left {
    height: 3px;
    background: #4252c9;
  }

  .header-right {
    height: 3px;
    background: #efefef;
  }

  .input-comment-attachment-button {
    background: #4252c9;
    width: 100%;
    margin-top: 0.5rem;
    font-size: 14px;
    border: none;
  }

  input {
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    height: 56px;

    @media screen and (max-width: 575.98px) {
      height: initial;
    }
  }
}

.comments-modal.modal-dialog {
  margin-top: 0 !important;
  margin-right: 0 !important;
  @media screen and (max-width: 575.98px) {
    margin-left: 0;
  }
}

.modal:has(.comments-modal) {
  --bs-modal-border-radius: 0;
  --bs-modal-border-color: none;
  overflow: hidden;
}

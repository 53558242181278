.comments-modal {
  .comments-text {
    margin-bottom: 0;
  }

  .modal-dialog {
    position: fixed !important;
    right: 0 !important;
    margin: 0 !important;
  }

  .modal-content {
    overflow: hidden;
    height: 100vh;

    .no-comments-text {
      font-weight: 600;
      font-size: 22px;
      line-height: 119.4%;

      text-align: center;
      letter-spacing: 0.05em;

      color: #888888;
    }

    .sub-text {
      font-weight: 300;
      margin-bottom: 0;
      line-height: 11px;
    }

    .comment-container {
      .user {
        font-weight: 600;
        font-size: 22px;
        line-height: 119.4%;
        letter-spacing: 0.05em;
        color: #000000;
        margin-bottom: 0;

        &.unread {
          font-weight: 500;
        }
      }

      small {
        font-weight: 300;
        font-size: 14px;
        line-height: 119.4%;

        color: #818181;

        &:first-child {
          margin-right: 1rem;
        }
      }

      .user-comment {
        text-align: justify;
      }

      .reply {
        font-weight: 400;
        font-size: 16px;
        line-height: 146.4%;
        text-align: right;
        text-decoration: none;

        color: #4252c9;
      }
    }

    .modal-body {
      overflow-y: auto;
    }
  }

  .modal-footer {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 50px;

    .send-btn-section {
      display: grid;
      justify-content: center;
    }
  }

  .header-left {
    height: 3px;
    background: #4252c9;
  }

  .header-right {
    height: 3px;
    background: #efefef;
  }

  .input-comment-attachment-button {
    background: #4252c9;
    width: 100%;
    margin-top: 0.5rem;
    font-size: 14px;
    border: none;
  }

  input {
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    height: 56px;

    @media screen and (max-width: 575.98px) {
      height: initial;
    }
  }
}

.comments-modal.modal-dialog {
  margin-top: 0 !important;
  margin-right: 0 !important;
  @media screen and (max-width: 575.98px) {
    margin-left: 0;
  }
}

.modal:has(.comments-modal) {
  --bs-modal-border-radius: 0;
  --bs-modal-border-color: none;
  overflow: hidden;
}

$background: "../../assets/img/BG.png";
$congBG: "../../assets/img/BG_3.png";
$addHeaderBG: "../../assets/img/BG_1.png";

@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.login-card-bg {
  background-color: white;
  min-height: 100vh;
}

.login-container {
  background: #f3f3f3 url($background) no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  padding-top: 5rem;
}

.login-form {
  .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }
  .form-control {
    padding-left: 2.375rem;
  }
  .form-label {
    font-weight: bold;
  }
  .submit-btn {
    background: #4c5cd2;
    box-shadow: 0px 9px 10px -2px rgb(76 92 210 / 31%);
    border-radius: 21px;
    padding: 0.9rem 3.5rem;
  }
}

.auth-forgot-password {
  text-decoration: none;
  margin-left: 2rem;
}

.e-checkbox-wrapper .e-label {
  font-family: "Montserrat", sans-serif !important;
}

.login-card {
  &--header-text {
    font-weight: 700;
  }
}

.custom-breadcrumb--comtainer {
  .breadcrumb-item {
    margin: 0;
  }
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.agency-select,
.party-select-list,
.cong-select,
.project-select {
  &--option {
    h6 {
      margin-left: 0.5rem;
    }
  }
  .select__option {
    img {
      display: none;
    }
  }
  .select__menu-list {
    &::-webkit-scrollbar {
      width: 20px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border-radius: 20px;
      border: 6px solid transparent;
      background-clip: content-box;
    }
  }
}

.view-comment-btn1 {
  color: white;
  background: #5a69da;
  border: 1px solid #5a69da;
  border-radius: 27px;
  margin-bottom: 0px !important;
  padding: 5px 10px;
}

.cong-header {
  background: url($congBG) no-repeat;
  background-position: bottom;
  height: 130px;
  background-size: cover;
}

.cong-dropdown-section {
  background: #f3f3f3;
}

.custom-navbar {
  @media (max-width: "991.98px") {
    .parent-container {
      justify-content: flex-end !important;
      margin-right: 2rem !important;
    }
  }

  .navbar-toggler--container {
    a {
      text-decoration: none;
      color: inherit;
    }
  }
}

.part-details {
  @media (max-width: 768.98px) {
    .party-logo {
      img {
        height: 100px;
      }
    }

    .row-section {
      .col-auto {
        width: 100px !important;
      }
    }

    h5 {
      font-size: 1rem;
    }
  }

  .row-section {
    .col-auto {
      width: 150px;
    }
  }

  @media (max-width: 575.98px) {
    .custom-breadcrumb--comtainer {
      display: none;
    }

    .party-logo {
      display: none;
    }

    .row-section {
      margin-right: 1rem !important;
      margin-left: 1rem !important;

      .col-auto {
        display: none;
      }
    }

    .select-container {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }

    .search-section {
      display: none !important;
    }

    .text-danger {
      display: none !important;
    }
  }

  .card {
    background-color: transparent;
    border: none;

    .rdt_Table,
    .rdt_TableHeadRow {
      background-color: #dde8ef;
      border-bottom: none;
    }

    .rdt_Table {
      .rdt_TableCol_Sortable {
        display: flex;
        justify-content: space-between;

        .sc-dmctIk {
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 0.02em;
          text-transform: uppercase;

          color: #818181;
        }
      }

      .rdt_TableCol_Sortable[data-column-id="action"] {
        justify-content: center;
      }

      .rdt_TableRow:hover {
        background-color: white;

        .rdt_TableCell[data-column-id="highlight"] {
          background-color: #4252c9;
        }
      }

      .rdt_TableCell[data-column-id="highlight"] {
        padding: 0;
      }

      .rdt_TableRow {
        background-color: #f8f8f8;
      }

      .badge {
        background-color: #df3348 !important;

        &.is-hidden {
          display: none;
        }
      }
    }
  }
}

.representatives-container {
  padding-right: 4rem;
  padding-left: 4rem;
  display: flex;
  justify-content: center;
}

@media (max-width: "768.98px") {
  .representatives-container {
    justify-content: flex-start !important;
  }
}

.text-bold {
  font-weight: bold;
}

.party-list {
  @media (max-width: 340px) {
    .affiliation-container {
      .pl-0.col {
        display: none;
      }

      .party-container {
        width: auto;
      }

      img {
        height: 60px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1199.98px) {
    .party-container {
      width: 300px;
    }
  }
}

.project-list {
  @media (max-width: 575.98px) {
    h1 {
      font-size: 1.25rem;
    }
  }

  @media (max-width: 340px) {
    .program-container {
      .pl-0.col {
        display: none;
      }

      width: auto !important;

      img {
        height: 60px;
      }
    }
  }

  // Large devices (desktops, less than 1200px)
  @media (max-width: 1199.98px) {
    .program-container {
      width: 300px;
    }
  }
}

@media (max-width: 575.98px) {
  .custom-navbar {
    .parent-container {
      margin-right: 0 !important;
    }
  }
}

.text-logo {
  @media (max-width: 575.98px) {
    img {
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.hor-logo {
  @media (max-width: 575.98px) {
    img {
      width: 100px;
    }
  }
}

.district-list {
  @media (max-width: 575.98px) {
    .row-section {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }

    .header-container {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
  }

  @media (max-width: 768.98px) {
    .custom-breadcrumb--comtainer {
      display: none !important;
    }
  }
}

.full-layout {
  @media (max-width: 991.98px) {
    .p-0.col-lg-7 {
      display: none;
    }
  }

  @media (min-width: 992px) {
    .login-container {
      padding-top: 8rem;
    }
  }
}

.login-form {
  .form-control:focus {
    box-shadow: none;
    border-color: #656eb2;
  }
}

.project-details {
  @media (max-width: "768.98px") {
    .project-logo {
      img {
        height: 100px;
      }
    }

    .row-section {
      .col-auto {
        width: 100px !important;
      }
    }
  }

  .row-section {
    .col-auto {
      width: 150px;
    }

    .budget-container {
      padding: 0;
      margin: 0;
      padding-bottom: 1rem;

      @media (max-width: 390px) {
        display: none !important;
      }
    }
  }

  @media (max-width: 575.98px) {
    .custom-breadcrumb--comtainer {
      display: none;
    }

    .row-section {
      margin-right: 1rem !important;
      margin-left: 1rem !important;

      .col-auto {
        display: none;
      }
    }

    .project-logo {
      display: none !important;
    }

    .select-container {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }

    .options-row {
      flex-direction: column;

      .project-select {
        margin-bottom: 2rem;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 768.98px) {
    .project-logo {
      width: auto !important;
    }
  }

  @media (min-width: 576px) and (max-width: 768.98px) {
    .project-logo {
      width: auto !important;
    }
  }

  .project-logo {
    display: grid;
    justify-content: center;
    width: 150px;
  }

  .card {
    background-color: transparent;
    border: none;

    .rdt_Table,
    .rdt_TableHeadRow {
      background-color: #dde8ef;
      border-bottom: none;
    }

    .rdt_Table {
      .rdt_TableCol_Sortable {
        display: flex;
        justify-content: space-between;

        .sc-dmctIk {
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 0.02em;
          text-transform: uppercase;

          color: #818181;
        }
      }

      .rdt_TableCol_Sortable[data-column-id="action"] {
        justify-content: center;
      }

      .rdt_TableRow:hover {
        background-color: white;

        .rdt_TableCell[data-column-id="highlight"] {
          background-color: #4252c9;
        }
      }

      .rdt_TableCell[data-column-id="highlight"] {
        padding: 0;
      }

      .rdt_TableRow {
        background-color: #f8f8f8;
      }

      .badge {
        background-color: #4252c9 !important;

        &.is-hidden {
          display: none;
        }
      }
    }
  }
}

.program-details {
  @media (max-width: "768.98px") {
    .project-logo {
      img {
        height: 100px;
      }
    }

    .row-section {
      .col-auto {
        width: 100px !important;
      }
    }
  }

  .row-section {
    .col-auto {
      width: 150px;
    }

    .budget-container {
      padding: 0;
      margin: 0;
      padding-bottom: 1rem;

      @media (max-width: 390px) {
        display: none !important;
      }
    }
  }

  @media (max-width: 575.98px) {
    .custom-breadcrumb--comtainer {
      display: none;
    }

    .row-section {
      margin-right: 1rem !important;
      margin-left: 1rem !important;

      .col-auto {
        display: none;
      }
    }

    .project-logo {
      display: none !important;
    }

    .select-container {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }

    .options-row {
      flex-direction: column;

      .project-select {
        margin-bottom: 2rem;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 768.98px) {
    .project-logo {
      width: auto !important;
    }
  }

  @media (max-width: "767px") {
    .row-section {
      .col-auto {
        display: none;
      }
    }
  }

  .project-logo {
    justify-content: center;
    display: grid;
    width: 150px;
  }

  .card {
    background-color: transparent;
    border: none;
    margin-bottom: 5rem;

    .rdt_Table,
    .rdt_TableHeadRow {
      background-color: #dde8ef;
      border-bottom: none;

      .sc-dmctIk {
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.02em;
        // text-transform: uppercase;
        color: #818181;
      }
    }

    .rdt_Table {
      .rdt_TableBody {
        min-height: 350px;
        max-height: calc(100vh - 200px);
      }

      .add-comment-btn {
        background: #f8f8f8;
        border: 1px solid #5a69da;
        border-radius: 27px;
        color: #5a69da;
      }

      .view-comment-btn {
        color: white;
        background: #5a69da;
        border: 1px solid #5a69da;
        border-radius: 27px;
      }

      .rdt_TableCol_Sortable {
        display: flex;
        justify-content: space-between;
      }

      .rdt_TableCol:last-child {
        .rdt_TableCol_Sortable {
          display: flex;
          justify-content: center;
        }
      }

      .rdt_TableRow:hover {
        background-color: white;

        .rdt_TableCell[data-column-id="highlight"] {
          background-color: #4252c9;
        }

        .custom-dropdown--container {
          .region {
            .select__control {
              background-color: white;
            }
          }
        }
      }

      .rdt_TableCell[data-column-id="highlight"] {
        padding: 0;
      }

      .rdt_TableRow {
        background-color: #f8f8f8;
      }

      .asyc-dropdown.top {
        .select__menu {
          top: auto;
          bottom: 100%;
        }
      }

      .badge {
        &.is-pending {
          background-color: #4252c9 !important;
        }

        &.is-requested {
          background-color: #f3c61c !important;
        }
        height: 0.75rem;

        div {
          display: none;
        }
      }

      .comment-section {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        position: relative;

        &:has(.unread-msgs) {
          bottom: 10px;
        }
      }

      .unread-msgs {
        height: initial;
        top: 9px;
        color: #5a69da;
        background-color: white !important;
        position: relative;
        box-shadow: 0px 4px 9px rgba(66, 82, 201, 0.38);
        font-size: small;
      }

      .select__control:has(.pending-status) {
        background-color: #4252c9;
        border: none;
        .pending-status {
          color: white;
        }
      }

      .select__control:has(.requested-status) {
        background-color: #f3c61c;
        border: none;
        .requested-status {
          color: black;
        }
      }

      .select__control:has(.processed-status) {
        background-color: #5a69da;
        border: none;
        .processed-status {
          color: white;
        }
      }

      .select__control:has(.in-progress-status) {
        background-color: #ffbd14;
        border: none;
        .in-progress-status {
          color: black;
        }
      }

      .select__control:has(.approved-status) {
        background-color: #53a24c;
        border: none;
        .approved-status {
          color: white;
        }
      }

      .select__control:has(.disapproved-status) {
        background-color: #df3348;
        border: none;
        .disapproved-status {
          color: white;
        }
      }

      .select__control:has(.downloaded-status) {
        background-color: #f26e01;
        border: none;
        .downloaded-status {
          color: white;
        }
      }

      .select__control:has(.resubmission-status) {
        background-color: #ff4433;
        border: none;
        .resubmission-status {
          color: white;
        }
      }

      .rdt_TableHead {
        .rdt_TableHeadRow {
          .rdt_TableCol:nth-child(2),
          .rdt_TableCol:nth-child(4) {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      .rdt_TableCol:first-child {
        padding: 0;
      }

      .rdt_TableCell:nth-child(5),
      .rdt_TableCell:nth-child(7),
      .rdt_TableCell:nth-child(8) {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}

.cong-container {
  .table-section {
    .col-auto {
      width: 150px;
    }
  }

  .custom-searchbar-container {
    input {
      background: #fbfeff;
    }
  }

  .breadcrumb {
    li {
      margin: 0;
    }
  }

  ol {
    margin-bottom: 0.5rem;
  }

  .file-upload {
    display: none;
  }

  .upload-btn {
    cursor: pointer !important;
    border-radius: 11px;
    border: 1px solid #d8d8d8;
    background: #4252c9;
    width: 206px;
    height: 50px;
    color: white;
    box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  @media (max-width: 575.98px) {
    .custom-breadcrumb--comtainer {
      display: none !important;
    }

    .cong-header,
    .cong-details {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }

    .project-logo {
      display: none !important;
    }

    .select-container,
    .table-section {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }

    .cong-header {
      .header-text {
        flex: 0 0 auto;
        width: 100%;
      }
    }

    .table-section {
      .col-auto {
        display: none;
      }
    }
  }

  @media (max-width: 900.98px) {
    .cong-details {
      flex-direction: column;

      .cong-img {
        align-self: center !important;
        margin-bottom: 1rem;
      }

      .row {
        display: flex;
        flex-direction: column;
        flex-direction: row;

        .mb-2.row {
          display: flex;
        }

        .details-section {
          flex: 0 0 auto;
          width: 100%;
        }

        .details-section:first-child {
          margin-bottom: 1rem;
        }
      }

      .text-weight-bold {
        font-size: 1rem;
        align-self: center;
      }
    }

    .committee-row {
      .col-auto {
        display: none;
      }
    }
  }

  @media (max-width: 991.98px) {
    .cong-header,
    .cong-details {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }

    .select-container,
    .table-section {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }

    .committee-row {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  }

  .table {
    background: #f3f3f3;
    margin: 0;
    padding: 0;

    .agency-selector,
    .search-section {
      background-color: transparent;
    }
  }

  .project-logo {
    width: 150px;
  }

  .card {
    background-color: transparent;
    border: none;
    padding: 0;

    .rdt_Table,
    .rdt_TableHeadRow {
      background-color: #f3f3f3;
      border-bottom: none;

      .sc-dmctIk {
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.02em;
        // text-transform: uppercase;
        color: #818181;
      }
    }

    .rdt_Table {
      .rdt_TableBody {
        min-height: 350px;
        max-height: calc(100vh - 200px);
      }

      .add-comment-btn {
        background: #f8f8f8;
        border: 1px solid #5a69da;
        border-radius: 27px;
        color: #5a69da;
      }

      .view-comment-btn {
        color: white;
        background: #5a69da;
        border: 1px solid #5a69da;
        border-radius: 27px;
      }

      .rdt_TableCol_Sortable {
        display: flex;
        justify-content: space-between;
      }

      .rdt_TableCol:first-child {
        display: none;
      }

      .rdt_TableCol:last-child {
        .rdt_TableCol_Sortable {
          display: flex;
          justify-content: center;
        }
      }

      .rdt_TableRow:hover {
        .rdt_TableCell[data-column-id="highlight"] {
          background-color: #4252c9;
        }
      }

      .rdt_TableCell[data-column-id="highlight"] {
        padding: 0;
      }

      .badge {
        &.is-pending {
          background-color: #4252c9 !important;
        }

        &.is-requested {
          background-color: #f3c61c !important;
        }
        height: 0.75rem;

        div {
          display: none;
        }
      }

      .comment-section {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        position: relative;

        &:has(.unread-msgs) {
          bottom: 10px;
        }
      }

      .unread-msgs {
        height: initial;
        top: 9px;
        color: #5a69da;
        background-color: white !important;
        position: relative;
        box-shadow: 0px 4px 9px rgba(66, 82, 201, 0.38);
        font-size: small;
      }

      .select__control:has(.pending-status) {
        background-color: #4252c9;
        border: none;
        .pending-status {
          color: white;
        }
      }

      .select__control:has(.requested-status) {
        background-color: #f3c61c;
        border: none;
        .requested-status {
          color: black;
        }
      }

      .select__control:has(.processed-status) {
        background-color: #5a69da;
        border: none;
        .processed-status {
          color: white;
        }
      }

      .select__control:has(.in-progress-status) {
        background-color: #ffbd14;
        border: none;
        .in-progress-status {
          color: black;
        }
      }

      .select__control:has(.approved-status) {
        background-color: #53a24c;
        border: none;
        .approved-status {
          color: white;
        }
      }

      .select__control:has(.disapproved-status) {
        background-color: #df3348;
        border: none;
        .disapproved-status {
          color: white;
        }
      }

      .select__control:has(.downloaded-status) {
        background-color: #f26e01;
        border: none;
        .downloaded-status {
          color: white;
        }
      }

      .select__control:has(.resubmission-status) {
        background-color: #ff4433;
        border: none;
        .resubmission-status {
          color: white;
        }
      }
    }
  }

  .custom-dropdown--container {
    padding: 0;
  }

  @media (max-width: 810.98px) {
    .committee-row {
      .committee-container {
        th {
          div {
            height: 30px !important;
          }
        }
        td {
          padding-left: 1rem !important;
          padding-right: 1rem !important;

          h5 {
            height: 25px !important;
          }
        }
      }
    }
  }

  @media (max-width: 538.98px) {
    .committee-row {
      h4 {
        font-size: 1rem !important;
      }
      .committee-container {
        td {
          padding-left: 0.5rem !important;
          padding-right: 0.5rem !important;
          p {
            width: 100%;
          }
        }
      }
    }
  }

  .committee-container {
    background: #f8f8ff !important;
    border: 2px solid #4053df;
    box-shadow: 0px 7px 24px rgba(64, 83, 223, 0.08);
    border-radius: 3px;

    .header {
      height: 60px;

      th {
        .col {
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .title {
      background: #ffffff;

      td {
        padding-left: 5rem;
        padding-right: 5rem;
      }

      h5 {
        align-items: center;
        display: flex;
        height: 60px;
        margin-bottom: 0 !important;
        font-weight: bold;
      }
    }

    .list {
      background: #ffffff;

      td {
        padding-left: 5rem;
        padding-right: 5rem;
      }

      p {
        align-items: center;
        display: flex;
        font-weight: 500;
        margin-bottom: 0;
        max-height: 60px;
      }
    }
  }

  .committee-row {
    margin-bottom: 3rem;

    .col-auto {
      width: 200px;
    }

    .budget-container.cong {
      @media (max-width: 375px) {
        display: none !important;
      }
    }
  }

  .cancel-btn {
    border-radius: 33px !important;
    background: #eff1ff !important;
    height: 42px;
    p {
      margin-bottom: 0;
      color: brown;
    }
  }
}

.mr-3 {
  margin-right: 3rem;
}

.ml-3 {
  margin-left: 3rem;
}

.mr-2 {
  margin-right: 2rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-25 {
  margin-left: 0.25rem;
}

.ml-75 {
  margin-left: 0.75rem;
}

.font-weight-bold {
  font-weight: bold;
}

.ml-5 {
  margin-left: 5rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-25 {
  margin-right: 0.25rem;
}

.mr-75 {
  margin-right: 0.75rem;
}

.mr-5 {
  margin-right: 5rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.mr-05 {
  margin-right: 0.5rem;
}

.ml-05 {
  margin-left: 0.5rem;
}

.add-project-container {
  .header {
    background: url($addHeaderBG) no-repeat;
    background-position: bottom;
    height: 200px;
    background-size: cover;

    .header-actions {
      padding-right: 8rem;
      padding-left: 8rem;
    }
  }

  .header-text,
  .main-container {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .input-date {
    background: #f5f5f7;
    border-radius: 16px;
    height: 60px;
  }

  .input-textarea {
    background: #f5f5f7;
    border-radius: 16px;
  }

  .main-container {
    .add-row-btn {
      width: 100%;
      border: 1px dashed rgba(0, 0, 0, 0.37);
      border-radius: 0;
      background-color: white;
      color: #999999;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .agency-container,
    .date-container {
      display: flex;
      flex-direction: row;

      .empty-space {
        width: 107px;
      }

      @media screen and (max-width: 575.98px) {
        .empty-space {
          display: none;
        }
      }
    }

    .flex-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .column {
        display: flex;
        width: 100%;
        gap: 1rem;
        padding-right: 1rem;
      }

      .container {
        flex: 1;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .atc-selection {
        justify-content: flex-end;
      }
    }

    .action-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    @media (max-width: 768.98px) {
      .flex-container {
        display: grid;
        grid-template-columns: 1fr 100px;

        .column {
          display: grid;
        }

        .action-section {
          justify-content: center;

          p {
            display: none;
          }
        }

        .atc-selection {
          margin-top: 1rem;
        }
      }
    }

    @media (max-width: 575.98px) {
      .flex-container {
        grid-template-columns: 1fr 50px;
      }
    }
  }

  .btn-save {
    background: #4252c9;
    box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
    border-radius: 11px;
    height: 50px;
    width: 140px;
  }

  .btn-cancel {
    background: #f3f3f3;
    box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
    border-radius: 11px;
    height: 50px;
    width: 140px;
    color: black;
    border: 0.5px solid #656eb2;
    font-weight: 600;
  }

  .add-proj-field {
    cursor: pointer;
    font-style: italic;
  }

  .asyc-dropdown {
    .select__control:hover {
      box-shadow: none;
    }
  }

  .material-input-field {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px;

    &:focus {
      box-shadow: none;
    }
  }

  .btn-add-another-proj {
    background: #f3f3f3;
    border: 0.5px solid #656eb2;
    box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
    border-radius: 11px;
    color: black;
    font-weight: bold;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .add-account-btn {
    background: #4252c9;
    box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
    border-radius: 11px;
    width: 200px;
    height: 45px;
  }

  a:has(.reset-account-btn) {
    color: transparent;
  }

  .reset-account-btn {
    background: #4252c9;
    box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
    border-radius: 11px;
    width: 230px;
    height: 45px;
  }

  a:has(.add-account-btn) {
    text-decoration: none;
  }

  ol {
    margin-bottom: 0;
  }

  .custom-breadcrumb--comtainer {
    margin-right: 8rem;
  }

  .table-section {
    margin-right: 8rem;
    margin-left: 8rem;
    margin-top: 5rem;
  }

  .card {
    background-color: transparent;
    border: none;
    padding: 0;

    .rdt_Table,
    .rdt_TableHeadRow {
      background-color: white;
      border-bottom: none;

      .sc-dmctIk {
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.02em;
        // text-transform: uppercase;
        color: #818181;
      }
    }

    .rdt_Table {
      .add-comment-btn {
        background: #f8f8f8;
        border: 1px solid #5a69da;
        border-radius: 27px;
        color: #5a69da;
      }

      .view-comment-btn {
        color: white;
        background: #5a69da;
        border: 1px solid #5a69da;
        border-radius: 27px;
      }

      .rdt_TableCol_Sortable {
        display: flex;
        justify-content: space-between;
      }

      .rdt_TableCol:last-child {
        .rdt_TableCol_Sortable {
          display: flex;
          justify-content: center;
        }
      }

      .rdt_TableRow:hover {
        .rdt_TableCell[data-column-id="highlight"] {
          background-color: #4252c9;
        }
      }

      .rdt_TableCell[data-column-id="highlight"] {
        padding: 0;
      }

      .badge {
        background-color: #4252c9 !important;
        height: 1rem;

        div {
          display: none;
        }
      }
    }
  }

  .form-section {
    margin-right: 8rem;
    margin-left: 8rem;
    margin-top: 3rem;
    padding-bottom: 5rem;

    .form-control.input {
      background: #f5f5f7;
      border-radius: 16px;
      height: 60px;
    }

    .img-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .file-upload {
        display: none;
      }

      .upload-btn {
        border-radius: 11px;
        border: 1px solid #d8d8d8;
        background: #4252c9;
        width: 206px;
        height: 50px;
        color: white;
        box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 1rem;
      }
    }
  }

  #add-account-form {
    .form-control {
      background: #f5f5f7;
      border-radius: 16px;
      height: 60px;

      &[type="password"] {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .form-control:disabled {
    background-color: initial;
    color: hsl(0, 0%, 60%);
  }

  @media screen and (max-width: 575.98px) {
    .custom-breadcrumb--comtainer {
      display: none !important;
    }

    .header-text,
    .main-container {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .mt-5.m-0.d-flex.justify-content-between.row:first-child {
      .col-6:last-child {
        .col-auto {
          display: none;
        }
      }
    }

    .btn-save-section {
      flex: 0 0 auto;
      width: 50%;
      .col-auto {
        display: none;
      }
    }

    .add-project-section {
      flex-direction: column-reverse !important;

      .add-proj-field {
        justify-content: flex-end !important;
        width: 100% !important;
      }

      .cancel-app-proj-btn {
        justify-content: flex-end !important;
        width: 100% !important;
        padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
        padding-bottom: 0.5rem !important;
        display: flex;
      }
    }

    .row-section {
      .col-1 {
        position: relative;
        top: -2rem;
      }
    }

    .date-section,
    .house-member-section {
      .col-6:last-child {
        .col-auto {
          display: none !important;
        }
      }
    }

    .col-auto:has(.btn-add-another-proj) {
      flex: 0 0 auto !important;
      width: 50% !important;
    }

    .btn-add-another-proj {
      padding: 0 0.5rem 0 0.5rem;
    }

    .header-actions {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }

    .form-section {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .btn-save,
    .btn-cancel {
      width: 120px;
    }
  }

  .add-project-section {
    display: flex;
    flex-direction: row;

    .add-proj-field {
      width: 107px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 1rem !important;
      text-decoration: none;
      color: initial;
    }

    .cancel-app-proj-btn {
      width: 107px;
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      align-items: center;
      display: flex;
    }
  }

  @include media-breakpoint-between(sm, lg) {
    .header-text,
    .main-container,
    .header-actions {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }

    .form-section,
    .table-section {
      margin-left: 3rem;
      margin-right: 3rem;
    }

    .custom-breadcrumb--comtainer {
      margin-right: 3rem;
    }

    .mt-5.m-0.d-flex.justify-content-between.row:first-child {
      .col-6:last-child {
        .col-auto {
          display: none;
        }
      }
    }

    .btn-save-section {
      .col-auto {
        display: none;
      }
    }

    .add-project-section {
      display: flex;

      .add-proj-field {
        justify-content: flex-end;
      }

      .cancel-app-proj-btn {
        width: 107px !important;
        padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
        padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
        align-items: center;
        display: flex;
      }
    }

    .date-section,
    .house-member-section {
      .col-6:last-child {
        .col-auto {
          display: none !important;
        }
      }
    }
  }
}

.dropzone-container {
  width: auto;
  height: 65px;
  border: 1px dashed #a3a3a3;

  .upload-files {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.navbar.fixed-right {
  width: 380px !important;
}

body {
  padding-top: 0 !important;
}

input[class="is-focused form-control"] {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.budget-allocation {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

.custom-dropdown--container {
  .is-pending {
    .select__single-value {
      font-weight: bold;
    }
  }
}

.reports-header {
  margin-left: 8rem;
  margin-right: 8rem;
  padding-top: 5rem;

  @include media-breakpoint-between(sm, lg) {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 2rem !important;
    margin-right: 2rem !important;

    h5 {
      font-size: 0.9rem !important;
    }
  }

  @include media-breakpoint-down(md) {
    .dropdown-open {
      display: none;
    }

    h5 {
      font-size: 1rem;
    }
  }

  .report-container {
    padding: 0;
    background: #f8f8ff;
    border: 1px solid #656eb2;
    box-shadow: 0px 52px 84px -19px rgba(121, 137, 255, 0.21);
    border-radius: 12px;
    max-height: 600px;
    margin-top: 2rem;
    overflow-y: auto;

    .report-item {
      margin: 0;
      max-height: 400px;
      border-bottom: 1px solid rgba(66, 82, 201, 0.5);
      overflow: hidden;
      overflow-y: auto;

      @include media-breakpoint-down(sm) {
        .parent-row {
          min-height: 50px;
          height: 100% !important;
        }
      }

      &:has(.parent-row.is-active) {
        border-bottom: none;
      }

      .parent-row.is-active {
        h5 {
          font-weight: bold;
        }

        border-bottom: 1px solid rgba(66, 82, 201, 0.5);
      }

      &:last-child {
        border-bottom: none;
      }

      .parent-row {
        height: 50px;
      }

      .project-level {
        max-height: 300px;
        padding-left: 3.5rem;
        padding-right: 3.5rem;
        background: #ffffff;
        border-bottom: 1px solid rgba(66, 82, 201, 0.5);

        &.is-active {
          .project-name,
          .project-budget {
            font-weight: bold;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        &--item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0;
          height: 40px;
        }

        .badge {
          background-color: #4252c9 !important;
          height: 1rem;
        }
      }

      .regional-level {
        border-bottom: 1px solid rgba(66, 82, 201, 0.5);

        &:has(.is-active) {
          border-bottom: none;
        }

        .project-level.is-active {
          .region-name,
          .region-budget {
            font-weight: bold;
          }
        }

        .project-level {
          max-height: 250px !important;
          padding-left: 5rem !important;
          padding-right: 5rem !important;

          .mb-0.ml-3 {
            margin-left: 1.5rem !important;
          }
        }

        .badge {
          background-color: #f05a67 !important;
        }

        .regional-level {
          padding-left: 2rem !important;
          padding-right: 2rem !important;
          background: #ffffff;

          .badge {
            background-color: #ffbd14 !important;
          }

          .dropdown {
            display: none;
          }
        }
      }
    }
  }
}

.profile-link {
  text-decoration: none;
  color: initial;
}

.sms-notif {
  .swal2-html-container {
    text-align: left;
  }
}

.save-btn {
  color: #4252c9 !important;
  border-radius: 33px !important;
  background: #eff1ff !important;
  height: 42px;
}

.resend-btn {
  border-radius: 3px !important;
  background-color: #4252c9 !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.modal-save-btn {
  background: #4252c9 !important;
  box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
  border-radius: 11px;
  height: 45px;
  width: 120px;
}

.edit-budget {
  background: #f5f5f7 !important;
  height: 45px;
}

// PROPERTY OVERRIDES

.wrap-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex-direction-col {
  flex-direction: column;
}

.news {
  &--custom-form {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    height: 100%;

    &:focus {
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      box-shadow: none !important;
      border-bottom: 1px solid #ced4da !important;
    }
  }
}

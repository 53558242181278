.year-picker-wrapper {
  border: solid 1px black;
  border-radius: 10px;
  padding: 10px;
  padding-left: 10px;
  margin-top: 15px;
  height: 45px;
  display: flex; /* Ensure alignment */
  align-items: center; /* Vertically center the dropdown */
  justify-content: center;
  align-content: center;
  overflow: visible;

  .year-select {
    width: 100px;

    &__control {
      border: none !important;
      box-shadow: none !important;
      background-color: transparent !important;

      &:hover {
        border: none;
      }
    }

    &__value-container {
      justify-content: center;
    }

    &__single-value {
      text-align: center;
    }

    &__menu {
      background-color: #f8f9fa;
    }

    &__indicator-separator {
      @media (max-width: 767px) {
        display: none;
      }
    }

    &__dropdown-indicator {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}

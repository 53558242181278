.others-field {
  display: -webkit-box !important;
}

.btn-save {
  background: #4252c9 !important;
  box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
  border-radius: 11px !important;
  height: 50px;
  width: 140px !important;
}

.btn-cancel {
  background: #f3f3f3 !important;
  box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
  border-radius: 11px !important;
  height: 50px;
  width: 140px !important;
  color: black !important;
  border: 0.5px solid #656eb2 !important;
  font-weight: 600 !important;
}
@media screen and (max-width: 575.98px) {
  .others-field {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
}

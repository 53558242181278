.party-container {
	background: #ffffff;
	border-radius: 11px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1.5rem;
	width: 400px;
	min-height: 125px;

	&--label {
		// line-height: 29px;
		letter-spacing: 0.02em;
		font-weight: 400;
	}

	a {
		text-decoration: none;
		color: var(--bs-body-color);
	}

	a:hover {
		color: var(--bs-link-color);
	}
}

@media (max-width: "768.98px") {
	.party-container {
		width: 300px;
		min-height: 100px;

		img {
			height: 70px;
		}
	}

	.affiliation-container {
		justify-content: flex-start !important;
	}
}

.affiliation-container {
	justify-content: center;
}

.directory-container {
  padding: 0;
  margin-right: 5rem;
  margin-left: 5rem;

  @media screen and (max-width: 575.98px) {
    .custom-breadcrumb--comtainer {
      display: none;
    }

    margin-right: 1rem;
    margin-left: 1rem;

    .add-btn {
      width: 100% !important;
    }

    .select-container {
      flex-direction: column !important;
    }
  }

  .card {
    background-color: transparent;
    border: none;

    .rdt_Table,
    .rdt_TableHeadRow {
      background-color: #dde8ef;
      border-bottom: none;
    }

    .rdt_Table {
      .rdt_TableCol_Sortable {
        display: flex;
        justify-content: space-between;

        .sc-dmctIk {
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 0.02em;
          text-transform: uppercase;

          color: #818181;
        }
      }

      .rdt_TableCol_Sortable[data-column-id="action"] {
        justify-content: center;
      }

      .rdt_TableRow:hover {
        background-color: white;

        .rdt_TableCell[data-column-id="highlight"] {
          background-color: #4252c9;
        }
      }

      .rdt_TableCell[data-column-id="highlight"] {
        padding: 0;
      }

      .rdt_TableRow {
        background-color: #f8f8f8;
      }

      .badge {
        background-color: #df3348 !important;

        &.is-hidden {
          display: none;
        }
      }
    }
  }

  .add-btn {
    background: #4252c9;
    box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
    border-radius: 11px;
    width: 200px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .photo-frame {
    width: 50px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #4252c9;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .select-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;

    .select-parent {
      flex: 1;
    }
  }
}

.add-project-container.directory {
  .header-actions {
    @media (max-width: 575.98px) {
      h2 {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.add-project-container.directory {
  .form-section {
    @media (max-width: 575.98px) {
      .col--img-container {
        margin-bottom: 2rem;
      }
    }

    @media (max-width: 768.98px) {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }
}

.program-container {
  background: #ffffff;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 400px;
  min-height: 125px;

  &--label {
    letter-spacing: 0.02em;
    font-weight: 400;
  }

  a {
    text-decoration: none;
    color: var(--bs-body-color);
  }

  a:hover {
    color: var(--bs-link-color);
  }
}

.project-container {
  .badge-container {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-end;
    height: 0px;

    .badge.requested-badge {
      background-color: #f3c61c !important;
      color: black;
    }

    .badge {
      position: relative;
      top: -10px;
      right: -10px;
      background-color: #4252c9 !important;
      box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.19);

      &.is-hidden {
        background-color: transparent !important;
        top: 0px;
        right: 0px;
        box-shadow: none;
        color: transparent;
      }
    }
  }
  .col-auto {
    position: relative;

    .badge.requested-badge {
      background-color: #f3c61c !important;
      color: black;
    }

    .badge {
      position: relative;
      top: 15px;
      right: -10px;
      background-color: #4252c9 !important;
      box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.19);

      &.is-hidden {
        background-color: transparent !important;
        top: 0px;
        right: 0px;
        box-shadow: none;
        color: transparent;
      }
    }
  }
}

.agency-container {
  justify-content: center;
}

@media (max-width: "768.98px") {
  .program-container {
    width: 300px;
    min-height: 100px;

    img {
      height: 70px;
    }
  }

  .agency-container {
    justify-content: flex-start;
  }
}

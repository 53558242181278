.custom-district-card {
  border-radius: 11px !important;
  border: none !important;
  width: 240px;
  height: 420px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 310px;
  text-decoration: none;
  color: initial;

  img {
    width: 100%;
  }

  h6 {
    .text-bold {
      font-weight: bold;
    }
  }

  :hover {
    // color: initial;
  }
}

@media (max-width: 575.98px) {
  .custom-district-card {
    grid-template-rows: 200px !important;
    height: 330px !important;
    width: 170px !important;

    img {
      height: 200px !important;
    }
  }
}

@media (max-width: 991.98px) {
  .custom-district-card {
    grid-template-rows: 260px;
    width: 210px;
    height: 400px;

    img {
      height: 260px;
    }
  }
}

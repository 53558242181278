@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.custom-navbar {
  a:nth-child(2) {
    // margin-right: 3rem;
    // margin-left: 5rem;
  }
  a:nth-child(3) {
    // margin-left: 3rem;
    // margin-right: 5rem;
  }
}

.custom-navbar.navbar {
  // height: 70px;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-around;
  padding: 0;

  .layout1-nav-links.active {
    font-weight: 500 !important;
    background: rgba(212, 222, 228, 0.4);
    border-radius: 3px;
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
  }

  .is-hidden {
    opacity: 0;
  }

  li {
    margin: 0;
  }

  .dropdown-section {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    margin-right: 1rem;
  }

  .nav-item {
    display: flex;
    align-items: center;
  }

  .user-dropdown.section {
    margin-left: 1rem;
    ul {
      padding-left: 1rem;
      padding: 0;
    }
  }

  @media (max-width: 768.98px) {
    .navbar-toggler--container,
    .dropdown-user-menu {
      // display: flex;
      // justify-content: flex-end;
    }

    justify-content: space-between;
  }

  &.spare {
    @media screen and (min-width: 2000px) {
      .navbar-toggler--container {
        // flex: 0 0 auto;
        // width: 25%;
      }
    }

    @media (min-width: 1500px) and (max-width: 1999px) {
      .navbar-toggler--container {
        // flex: 0 0 auto;
        // width: 40%;
        .navbar-collapse {
          // justify-content: center;
        }
      }
    }

    @media (min-width: 1200px) {
      .navbar-toggler--container {
        .navbar-collapse {
          // justify-content: center;
        }
      }
    }

    li:nth-child(3) {
      // display: inline-flex;
      // align-items: baseline;

      .badge {
        position: relative;
        left: 8.5rem;
        top: -1rem;
        background-color: #4252c9 !important;
        box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.19);
      }
    }
  }

  @media (min-width: "1200px") {
    .btn-container {
      // justify-content: flex-end;
    }
  }

  &.home {
    .row {
      justify-content: flex-end;

      @media (min-width: "2560px") {
        .nav-item {
          width: 300px;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      .nav-items {
        flex: 0 0 auto;
        width: 66.66666667%;
      }
    }

    li:nth-child(3) {
      display: inline-flex;
      align-items: baseline;

      .badge {
        position: relative;
        left: 8rem;
        top: -0.85rem;
        background-color: #4252c9 !important;
        box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.19);
      }
    }
  }

  .navbar-brand {
    margin-right: 0 !important;
    padding-left: 1rem;
  }

  &.congressman {
    justify-content: space-between;
  }

  &.cos {
    justify-content: space-between;
  }
}

.custom-navbar.white-bg {
  background-color: white;
}

.navbar-toggler--container {
  .collapse {
    // justify-content: flex-end;
  }

  .dropdown {
    // float: right !important;
  }

  .dropdown-menu.show {
    // transform: translate3d(0px, 30px, 0px) !important;
  }

  // display: flex;
  // align-items: center;
}

.custom-navbar.has-shadow {
  box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.08);
}

.dropdown-user-menu {
  a:nth-child(3) {
    margin-left: 0;
    margin-right: 0;
  }

  a:nth-child(2) {
    margin-right: 0;
    margin-left: 0;
  }

  border: none !important;

  .dropdown-menu.show {
    transform: translate3d(0px, 40px, 0px) !important;
    .profile-link {
      &:hover {
        color: initial;
        background-color: initial;
      }
    }
  }
}

.add-project-btn {
  min-width: 175px;
  height: 45px;
  background: #4252c9 !important;
  box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
  border-radius: 11px !important;
  border: none !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.user-dropdown {
  &:hover {
    background-color: initial !important;
    cursor: pointer;
  }

  .avatar {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 5px;

    .user-profile {
      margin-right: 1rem;
    }
  }
}

.user-avatar {
  width: 150px;
  background: white;
  box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
  border-radius: 11px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin-right: 0.5rem;
    font-size: 18px;
    font-weight: 400;
  }
}

.search-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;

  input {
    background: transparent;
  }

  @media (max-width: 768px) {
    width: 150px; /* Adjust for tablets */
  }

  @media (max-width: 576px) {
    width: 110px; /* Adjust for mobile phones */
  }

  @media (max-width: 360px) {
    width: 100px; /* Adjust for mobile phones */
  }
}

.dropdown-user-menu {
  a:nth-child(3) {
    margin-left: 0;
    margin-right: 0;
  }

  a:nth-child(2) {
    margin-right: 0;
    margin-left: 0;
  }

  border: none !important;

  .dropdown-menu.show {
    transform: translate3d(0px, 30px, 0px) !important;
  }
}

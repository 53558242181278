$congBG: "../../../assets/img/BG_3.png";

.summary-report {
  &--container {
    padding-left: 8rem;
    padding-right: 8rem;
    background: url($congBG) no-repeat;
    background-position: bottom;
    height: 130px;
    background-size: cover;

    .custom-breadcrumb--comtainer {
      margin-top: 1rem;
    }

    .header {
      height: 75%;
    }

    @media (max-width: 575.98px) {
      padding-left: 1rem;
      padding-right: 1rem;

      .custom-breadcrumb--comtainer {
        display: none !important;
      }

      .header {
        height: 100%;
      }

      .main {
        margin-top: 3rem !important;
      }

      .header-text {
        display: flex;
        justify-content: center;
      }
    }

    @media (min-width: 576px) and (max-width: 1024.98px) {
      padding-left: 5rem;
      padding-right: 5rem;

      .date-section {
        .col-6:last-child {
          .col:last-child {
            margin-top: 1rem;
          }
        }
      }
    }

    .main {
      margin-top: 5rem;

      .input-date {
        background: #f5f5f7;
        border-radius: 16px;
        height: 60px;
      }

      .btn-export {
        background: #4252c9;
        box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
        border-radius: 11px;
        height: 50px;
        width: 180px;
      }

      .btn-export-section {
        padding-top: 2rem;
        padding-bottom: 5rem;
      }

      .react-datepicker-wrapper {
        width: 100%;
      }
    }
  }
}

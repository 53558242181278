.custom-searchbar-v2-container {
  .form-control {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background: #eef2f4;
    background-clip: padding-box;
    border: 1px solid #d9d9d9;
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.show {
  display: block;
}

.layout1-nav-links {
  font-family: "Montserrat";
  font-weight: 400 !important;
  line-height: 119.4%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #33363f !important;
}

.layout1-dir-hor-text {
  color: #33363f;
}

.has-icon-left .form-control {
  padding-right: 2rem;
  padding-left: 3rem;
}

.form-control-position {
  position: absolute;
  top: 0px;
  z-index: 1;
  display: block;
  width: 2.5rem;
  height: 100%;
  line-height: 2.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
}

.custom-searchbar-container {
  input {
    border: none;
    background: #f8f8ff;
    box-shadow: 0px 4px 9px rgba(66, 82, 201, 0.38);

    &:focus {
      box-shadow: 0px 4px 9px rgba(66, 82, 201, 0.38);
    }
  }
}

.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  display: inline-block;
  padding: 4px 6px;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  max-width: 100%;
  line-height: 22px;
  cursor: text;
}
.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit;
}
.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #777;
  opacity: 1;
}
.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  color: #777;
}
.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: #777;
}
.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}
.bootstrap-tagsinput .tag {
  margin-right: 2px;
  color: white;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "x";
  padding: 0px 2px;
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.resend-modal {
  &--container {
    padding: 0 !important;
    margin-bottom: 1rem !important;
  }

  .msg-body {
    margin-top: 1rem;

    textarea {
      background: #f5f5f7;
    }
  }

  .tags {
    .form-control {
      background: #f5f5f7;
      height: 45px;

      div {
        margin-bottom: 0.25rem;
      }
    }

    background: #f5f5f7;

    input {
      display: none;
    }
  }

  .badge {
    background: #656eb2 !important;
  }

  .single {
    background: #f5f5f7;
  }

  .btn-save {
    background: #4252c9;
    box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
    border-radius: 11px;
    height: 45px;
    width: 120px;
  }

  .btn-cancel {
    background: #f3f3f3;
    box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
    border-radius: 11px;
    height: 50px;
    width: 140px;
    color: black;
    border: 0.5px solid #656eb2;
    font-weight: 600;
  }
}

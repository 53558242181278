.custom-spinner {
    &.overlayed {
        position       : absolute;
        display        : flex;
        left           : 0;
        right          : 0;
        justify-content: center;
        z-index: 2;

        .spinner-border-sm.spinner-border {
            color: #FFF !important;
        }
    }

    &.backdrop {
        align-items: center;
        background-color: #7e7e7e3d;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        vertical-align: middle;
    }
}
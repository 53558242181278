.custom-input-group {
  .form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;

    &:focus {
      box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    }

    &:has(.select__control--is-focused) {
      border-color: #86b7fe;
    }
  }

  &:has(.form-control:focus) {
    button {
      box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
      border-color: #86b7fe;
    }
  }

  &:has(.select__control--is-focused) {
    button {
      border-color: #86b7fe;
    }
  }

  button {
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    border-top: none;
    border-right: none;
    border-left: none;
  }
}

.program-details {
  .rdt_Table {
    .rdt_TableRow {
      @media screen and (max-width: 768.98px) {
        .rdt_TableCell[data-column-id="badge"],
        .rdt_TableCell[data-column-id="dateRequested"],
        .rdt_TableCell[data-column-id="dateApproved"],
        .rdt_TableCell[data-column-id="refCode"],
        .rdt_TableCell[data-column-id="budgetUtilization"],
        .rdt_TableCell[data-column-id="SAA"],
        .rdt_TableCell[data-column-id="updatedBy"] {
          display: none;
        }
      }

      .rdt_TableCell[data-column-id="checkbox-hidden"] {
        display: none;
      }
    }

    .rdt_TableHead {
      .rdt_TableHeadRow {
        @media screen and (max-width: 768.98px) {
          .rdt_TableCol[data-column-id="badge"],
          .rdt_TableCol[data-column-id="dateRequested"],
          .rdt_TableCol[data-column-id="dateApproved"],
          .rdt_TableCol[data-column-id="refCode"],
          .rdt_TableCol[data-column-id="budgetUtilization"],
          .rdt_TableCol[data-column-id="SAA"],
          .rdt_TableCol[data-column-id="updatedBy"] {
            display: none;
          }
        }

        .rdt_TableCol[data-column-id="checkbox-hidden"] {
          display: none;
        }
      }
    }

    .rdt_TableBody {
      overflow-y: scroll;
    }
  }
}

.search-bar {
  width: auto !important;
}

.other-info {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Change this value to the desired number of lines */
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em;
  // line-height: 1.5em;
  width: 100%;
  white-space: pre-line;
}

@media (max-width: "1680px") {
  .status-filter {
    width: 50% !important;
  }
}
@media (max-width: "1368px") {
  .status-filter {
    width: 50% !important;
  }
}
@media (max-width: "1280px") {
  .status-filter {
    width: 60% !important;
  }
}
@media (max-width: "1180px") {
  .status-filter {
    width: 70% !important;
  }
  .search-status-row {
    flex-direction: column;
    align-items: flex-start;
  }
}

.delete-multiple-btn {
  width: 100%;
  border-radius: 0;
  background-color: #df3348 !important;
  color: #999999;
  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
